.container {
  padding: 0rem 2rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.wrapper {
  position: relative;
  align-items: baseline;
  display: flex;
  justify-content: center;
}
.wrapper > img {
  width: 30rem;
  position: absolute;
  bottom: 0;
}
.bluecircle {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  z-index: -99;
  background: linear-gradient(159.74deg, #0bb5ff 14.09%, #6dd3fe 80.81%);
  position: absolute;
  bottom: 0;
}
.cart2{
  position: absolute;
  bottom: 1%;
  right: -40%;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.cart2>svg{
  width: 30px;
  height: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  border: 6px solid black;
}
.signup{
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: white;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 15px;
  box-shadow: var(--shadow1);
}


.h_sides {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.text1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  width: min-content;
}
.text2 {
  display: flex;
  flex-direction: column;
  width: min-content;
  color: black;
}
.text2 > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}
.text2 > :last-child {
  display: block;
}
.traffic {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.traffic > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}
.customer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.customer > :first-child {
  font-weight: 800;
  text-align: right;
  font-size: 2.5rem;
}
.customer > :last-child {
  font-weight: 400;
  text-align: right;
}
