.s-container{
    position: relative;
}

.s-container .swiper{
    width: 70%;
    height: 12rem;
    position:unset;
    margin-top: 6%;
}
.s-container .swiper-slide{
    background-color: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.s-container .swiper-button-prev, .s-container .swiper-button-next{
    color: black;
}
.s-container .swiper-button-prev{
    left: 9rem;
}
.s-container .swiper-button-next{
    right: 9rem;
}
.left-s{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.name{
    display: flex;
    flex-direction: column;
}
.name>:nth-child(1){
    font-weight: 800;
    font-size: 1.4rem;
}
.name>:nth-child(2){
    font-size: 0.7rem;
}
.left-s>:nth-child(2){
    font-weight: bold;
    font-size: 2rem;
}
.left-s>:nth-child(3){
    font-size:0.6rem;
    border: 1px solid black;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}
.img-p{
    transform: rotate(-20deg);
    position: absolute;
right: 0;
width: 28%;
height: 100%;
bottom: -20%;
}